export const ALL_THEMES = '/accounts/v1/themes';
export const SELECTED_THEME = '/accounts/v1/themes/';
export const SAVE_USER_THEME = '/accounts/v1/users/preferences/';
export const CREATE_USER_THEME = '/accounts/v1/themes/';
export const TEMPLATE_UPLOAD = '/template-modeler/v1/templates/upload?type=text';
export const TEMPLATE_DOWNLOAD = '/template-modeler/v1/templates/download';
export const ALL_TEMPLATES = '/template-modeler/v1/templates?filter-column=type&filter-value=text';
export const TEMPLATES_DELETE = '/template-modeler/v1/templates/';
export const TICKET_CASE_ENGINE = '/camunda/service/v1/process-instance/start';
export const FORMS = '/form-runtime/v1/forms';
export const THEME_UPLOAD = '/accounts/v1/themes/import';
export const THEME_DOWNLOAD = '/accounts/v1/themes';
export const ROLES = '/accounts/v1/keycloak/roles';
export const UPLOAD_KEYCLOAK_THEME_IMAGES = '/dms/v1/file?documentTypeId=';
export const USER_PREFERENCE = '/accounts/v1/users/preferences';
export const MENU = '/accounts/v1/roles/menu';
export const FILE_UPLOADS = '/util/v1/file-upload?type=';
export const DOWNLOAD_ID = '/dms/v1/documents/download?id=';
export const WORKFLOW_START = '/workflow/v1/process/start';
